import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';
import { PostsAttribute, PostSceneAttribute } from "../../../types/types";

@Injectable()
export class TodayService extends BaseAPIService {

    private ROUTES = {
        heroList: '/today/hero/list',
        heroCreate: '/today/hero',
        heroUpdate: '/today/hero',
        heroDelete: '/today/hero/:id',
        getPost: '/today/post/:id',
        postList: '/today/post/list',
        postListWithPresetId: '/today/post/list/:presetId',
        postListWithFortuneId: '/today/post/list/fortune/:fortuneId',
        postCreate: '/today/post',
        postUpdate: '/today/post',
        postDelete: '/today/post/:id',
        postsDelete: '/today/post/delete',
        postsCopy: '/today/post/copy',
        presetList: '/today/post/preset/list',
        getPreset: '/today/post/preset/:id',
        presetCreate: '/today/post/preset',
        presetUpdate: '/today/post/preset',
        presetDelete: '/today/post/preset/:id',
        slotList: '/today/post/slot/list',
        slotUpdate: '/today/post/slot',
        checkSlotStatus: '/today/post/slot/checkstatus',
        sceneList: '/today/post/scene/list',
        getScene: '/today/post/scene/:id',
        sceneCreate: '/today/post/scene',
        sceneUpdate: '/today/post/scene',
        sceneDelete: '/today/post/scene/:id',
        sceneCopy: '/today/post/scene/copy',
        getSceneRelatedPost: '/today/post/scene/relatedpost/:id',
        postUpload: '/today/post/upload',
        chapterList: '/chapter/list/v2'
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    heroList(): Promise<any> {
        return this.http.get(this.ROUTES.heroList).toPromise();
    }

    heroCreate(body): Promise<any> {
        return this.http.post(this.ROUTES.heroCreate, body).toPromise();
    }

    heroUpdate(body): Promise<any> {
        return this.http.patch(this.ROUTES.heroCreate, body).toPromise();
    }

    heroDelete(id): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.heroDelete, { id })).toPromise();
    }

    getPost(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getPost, { id })).toPromise();
    }

    getSceneRelatedPost(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getSceneRelatedPost, { id })).toPromise();
    }

    postList(): Promise<any> {
        return this.http.get(this.ROUTES.postList).toPromise();
    }

    getPostListWithPresetId(presetId): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.postListWithPresetId, { presetId })).toPromise();
    }

    getPostListWithFortuneId(fortuneId): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.postListWithFortuneId, { fortuneId })).toPromise()
    }

    postCreate(post: PostsAttribute): Promise<any> {
        return this.http.post(this.ROUTES.postCreate, { post }).toPromise();
    }

    postUpdate(body): Promise<any> {
        return this.http.patch(this.ROUTES.postCreate, body).toPromise();
    }

    postDelete(id, leafletId: string): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.postDelete, { id }), { params: { leafletId } }).toPromise();
    }

    postsDelete(postIds: number[], leafletIds: number[]): Promise<any> {
        return this.http.post(this.ROUTES.postsDelete, { postIds, leafletIds }).toPromise();
    }

    postsCopy(postIds: number[]): Promise<any> {
        return this.http.post(this.ROUTES.postsCopy, { postIds }).toPromise();
    }

    getPreset(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getPreset, { id })).toPromise();
    }

    presetList(): Promise<any> {
        return this.http.get(this.ROUTES.presetList).toPromise();
    }

    presetCreate(body): Promise<any> {
        return this.http.post(this.ROUTES.presetCreate, body).toPromise();
    }

    presetUpdate(body): Promise<any> {
        return this.http.patch(this.ROUTES.presetUpdate, body).toPromise();
    }

    presetDelete(id: number): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.presetDelete, { id })).toPromise();
    }

    slotist(): Promise<any> {
        return this.http.get(this.ROUTES.slotList).toPromise();
    }

    slotUpdate(slotList, none): Promise<any> {
        return this.http.patch(this.ROUTES.slotUpdate, { slotList, none }).toPromise();
    }

    checkSlotStatus(): Promise<any> {
        return this.PATCH(this.ROUTES.checkSlotStatus).toPromise();
    }

    getScene(id): Promise<any> {
        return this.http.get(this.inject(this.ROUTES.getScene, { id })).toPromise();
    }

    sceneList(): Promise<any> {
        return this.http.get(this.ROUTES.sceneList).toPromise();
    }

    sceneCreate(scene: PostSceneAttribute): Promise<any> {
        return this.http.post(this.ROUTES.sceneCreate, { scene }).toPromise();
    }

    sceneUpdate(scene: PostSceneAttribute): Promise<any> {
        return this.http.patch(this.ROUTES.sceneUpdate, { scene }).toPromise();
    }

    sceneDelete(id: number): Promise<any> {
        return this.http.delete(this.inject(this.ROUTES.sceneDelete, { id })).toPromise();
    }

    sceneCopy(ids: number[]): Promise<any> {
        return this.http.post(this.ROUTES.sceneCopy, { ids }).toPromise();
    }

    postUpload(posts) {
        return this.POST(this.ROUTES.postUpload, null, { posts }).toPromise();
    }

    chapterList(): Promise<any> {
        return this.http.get(this.ROUTES.chapterList).toPromise();
    }

}
