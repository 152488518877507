export interface CategoryAttribute {
    id?: number;
    name?: string;
    pid?: number;
    code?: any;
}

export interface AdAreasAttribute {
    id?: number;
    name?: string;
    description?: string;
    width?: number;
    height?: number;
    akey?: string;
    category?: string;
    meta?: any;
    tags?: string[];
    AdItems?: AdItemsAttribute[];
    adAreaTags?: AdAreaTagAttribute[];
}

export interface AdAreaTagAttribute {
    area_id?: number;
    name?: string;
}

export interface ItemExtrasAttribute {
    id?: number;
    item_type?: string;
    name?: string;
    v?: any;
    ItemExtraImages?: ItemExtraImagesAttribute[];
}

export interface ItemExtraImagesAttribute {
    id?: number;
    extra_id?: number;
    name?: string;
    url?: string;
}

export interface LeafletsAttribute {
    id?: number;
    title?: string;
    code?: string;
    type?: string;
    fixed_position?: number;
    status?: any;
    isActive?: boolean;
    hidden?: boolean;
    thumbnail_image?: string;
    og_title?: string;
    og_image?: string;
    og_description?: string;
    meta?: any;
    start_at?: any;
    end_at?: any;
    ios?: boolean;
    android?: boolean;
    onestore?: boolean;
    web?: boolean;
    min_ver_num?: any;
    max_ver_num?: any;
    created_at?: number;
    updated_at?: number;
    reservation?: any;
    Reservations?: Array<ReservationsAttribute>;
    categoryList?: any;
    targeting?: TargetAttribute;
}

export interface AdItemsAttribute {
    id?: number | string;
    area_id?: string;
    image_url?: string;
    active?: any;
    is_admin?: any;
    target?: any;
    priority?: number;
    bg_color?: any;
    isChangeActive?: any;
    selectable?: boolean;
    fileName?: string;
    android?: boolean;
    ios?: boolean;
    onestore?: boolean;
    web?: boolean;
    min_ver_num?: any;
    max_ver_num?: any;
    reservation?: any;
    Reservations?: Array<ReservationsAttribute>;
    targeting?: any;
}

export interface ChaptersAttribute {
    id?: number;
    name?: string;
    extra?: string | any;
    active?: boolean;
    created_at?: any;
    updated_at?: any;
    PremiumItems?: PremiumItemsAttribute[];
    bindLabel?: string;
    value?: string;
}

export interface ChapterScriptsAttribute {
    id?: number;
    chapter_id?: number;
    active?: any;
    script?: string;
}

export interface ChapterResultSheetsAttribute {
    chapter_id?: number;
    key?: string;
    params?: string;
    detail?: string;
}

export interface BannerItemsAttribute {
    id?: number;
    label_text?: string;
    color?: number | string;
    title?: string;
    subtitle?: string;
    theme?: string;
    hero_image?: string;
    button_label?: string;
    item_id?: any;
    link?: string;
    on_live?: any;
    priority?: string;
    created_at?: string;
    updated_at?: string;
    banner_image?: string;
}

export interface EventItemsAttribute {
    event_id?: number;
    item_id?: any;
    priority?: number;
    feature?: number;
}

export interface EventAttribute {
    id?: number | null;
    uuid?: string;
    label_text?: string;
    color?: any;
    title?: string;
    subtitle?: string;
    theme?: string;
    hero_image?: string;
    button_label?: string;
    item_id?: any | undefined;
    link?: any;
    description?: any;
    created_at?: any;
    updated_at?: any;
    share_image?: any;
    area_code?: any;
    p_title?: any;
    sort_by?: any;
    FortuneItems?: any[];
    on_live?: any;
    priority?: any;
}

export interface FAQItemsAttribute {
    id?: number;
    active?: number | boolean;
    title?: string;
    content?: string;
    priority?: number;
    faqTags?: any[];
}

export interface FortuneItemTagsAttribute {
    item_id?: number;
    name?: string;
}

export interface toolAttribute {
    icon?: any;
    actionName?: string;
    tooltipText?: string;
}

export interface FortuneItemsAttribute {
    id?: number | string;
    cat_id?: number;
    guardian_id?: number;
    label_text?: string;
    color?: string;
    title?: string;
    subtitle?: string;
    created_at?: any;
    updated_at?: any;
    released_at?: any;
    layout?: any;
    theme?: any;
    description?: any;
    has_bubble?: number;
    image_pack_id?: number;
    share_title?: string;
    share_image?: any;
    expiry?: any;
    extra_id?: number;
    calc_text?: any;
    prv_title?: any;
    view_image?: any;
    template_id?: number;
    button_color?: any;
    code?: any;
    $or?: any;
    ListItem?: any;
    FortuneItemTags?: any;
    ThemeViewGenres?: any;
    main?: any;
    sub?: any;
    score?: any;
    status?: any;
    Reservations?: Array<any>;
    FortuneScripts?: Array<any>;
    liveStatus?: boolean;
    bindLabel?: string;
}

export interface FortuneResultsAttribute {
    key?: string;
    item_id?: number;
    user_id?: any;
    result?: string;
    created_at?: any;
    expires_at?: any;
}

export interface FortuneScriptsAttribute {
    id?: any;
    item_id?: any;
    script?: any;
    active?: any;
}

export interface GuardiansAttribute {
    id?: any;
    name?: any;
    class?: any;
    schools?: any;
    animal?: any;
    avatar_img_url?: any;
    card_img_url?: any;
    description?: any;
    selected?: boolean;
}

export interface ImagePackItemsAttribute {
    id?: number;
    image_pack_id?: number;
    name?: string;
    url?: string;
    fileName?: string;
}

export interface ImagePacksAttribute {
    id?: number;
    guardian_id?: number;
    name?: string;
    view_image?: string;
    default_image?: string;
    ImagePackItems?: ImagePackItemsAttribute[];
}

export interface ImageTemplatesAttribute {
    id?: number;
    name?: string;
    template?: string;
    sample_image?: string;
    share_template?: string;
    share_sample_image?: string;
    params?: any;
    active?: any;
    shareParams?: any;
    canvas_template?: string;
    svg_template?: string;
}

export interface ItemExtraImagesAttribute {
    id?: number;
    extra_id?: number;
    name?: string;
    url?: string;
}

export interface ItemExtrasAttribute {
    id?: number;
    item_type?: string;
    name?: string;
}

export interface ListItemsAttribute {
    id?: any;
    title?: any;
    label_text?: any;
    color?: any;
    hero_image?: any;
    teller_name?: any;
    teller_image?: any;
    teller_field?: any;
    has_teller?: any;
    has_hero_image?: any;
}

export interface ResultSheetsAttribute {
    id?: any;
    key?: any;
    params?: any;
    detail?: any;
}

export interface SectionsAttribute {
    id?: number;
    code?: string;
    name?: string;
    image_url?: string;
    title?: string;
    description?: string;
}

export interface OpenGraphAttribute {
    id?: number;
    category?: string;
    code?: string;
    name?: string;
    image_url?: string;
    url?: string;
    title?: string;
    description?: string;
}

export interface targetUserQueryAttribute {
    id?: number;
    title?: string;
    execute_query?: string;
    active?: boolean;
    created_at?: number;
    updated_at?: number;
}

export interface pushReservationAttribute {
    id?: number;
    param?: string;
    ref_id?: number;
    reservation_time?: number;
    status?: string;
    task?: string;
    type?: string;
    pushInfo?: pushInfoAttribute;
}

export interface pushInfoAttribute {
    id?: number;
    notification?: string;
    push?: string;
    gift?: string;
    query_id?: number;
    title?: string;
    created_at?: number;
    updated_at?: number;
    targetUserQuery?: targetUserQueryAttribute;
}

export interface push {
    is?: boolean;
    template_id?: string;
    headings?: string;
    contents?: string;
}

export interface notification {
    is?: boolean;
    content?: string;
    target?: string;
}

export interface gift {
    is?: boolean;
    id?: number;
    title: string;
    expires_at?: string;
}

export interface SocialAccountsAttribute {
    provider_id?: number;
    account_id?: string;
    user_id?: string;
    first_name?: string;
    last_name?: number;
    full_name?: any;
    avatar_url?: any | null;
    email?: number;
    created_at?: string;
    updated_at?: string;
}

export interface StaticPagesAttribute {
    id?: number;
    category?: string;
    title?: string;
    active?: null | number;
    content?: string;
    created_at?: any;
    updated_at?: any | null;
}

export interface TalkBoxesAttribute {
    id?: any;
    title?: any;
    active?: any;
    extra?: any;
    bgColor?: any;
    guardianImage?: any;
}

export interface UserProfilesAttribute {
    id?: number;
    calendar?: string;
    year?: number;
    month?: number;
    day?: number;
    hour?: number;
    min?: number;
    hm_unsure?: boolean | null;
    gender?: string;
    birth_in_millis?: number;
    location_id?: number;
    City?: CityAttribute;
}

export interface UserMyLocationAttribute {
    id?: number;
    my_device_tz?: string;
    my_Location_id?: number;
    my_Location_coords?: string;
}

export interface CityAttribute {
    id?: number;
    name?: string;
    longitude?: number;
    latitude?: number;
    timezone_id?: string;
    created_at?: any;
    updated_at?: any;
    country?: string;
    state?: string;
}

export interface UsersAttribute {
    id?: number;
    name?: string;
    email?: string;
    avatar_url?: string;
    active?: any;
    created_at?: any;
    updated_at?: any | null;
    admin?: number;
    UserProfile?: UserProfilesAttribute;
    Purchases?: Array<PurchasesAttribute>;
    ForceWithdrawals?: Array<ForceWithdrawalsAttribute>;
    ForceDeposits?: ForceDepositsAttribute[];
    Devices?: Array<DevicesAttribute>;
    UserMyLocation?: UserMyLocationAttribute;
}

export interface DevicesAttribute {
    id?: number;
    user_id?: number;
    uuid?: string;
    os?: string;
    version?: string;
    active?: boolean | number;
    meta?: string;
    created_at?: any;
    updated_at?: any;
    name?: string;
    platform?: string;
    appVersion?: string;
    userLog?: Array<UserLogAttribute>;
}

export interface UserLogAttribute {
    id?: number;
    device_id?: number;
    last_login_at?: any;
}

export interface ReservationsAttribute {
    id?: number;
    type?: string;
    status?: string;
    task?: string;
    reservation_time?: number;
    ref_id?: number;
    param?: any;
}

export interface TagsAttribute {
    id: number;
    // collate UTF8_GENERAL_CI (문자열 콜렉션을 정해주는 건데 UTF8_UNICODE_CI 보다 정확성은 떨어지지만 속도는 더 빠르다/)
    name: string;
    CelebTags?: Array<CelebTagsAttribute>;
}

export interface CelebPropsAttribute {
    id: number;
    name: string;
    value: string;
}

export interface CelebTagsAttribute {
    id: number;
    celeb_id: number;
    prop_name: string;
    tag_id: number;
}

export interface ClientsAttribute {
    id?: number;
    name?: string;
    birth_info?: number | string;
    gender?: string;
    created_at?: any;
    updated_at?: any;
    note?: string;
    city_id?: number;
}

export interface CelebThemeItemsAttribute {
    theme_id?: number;
    celeb_id?: number;
    priority: number;
}

export interface CelebThemesAttribute {
    id: number;
    uuid: string;
    title: string;
    hero_image: string;
    status?: string;
    extra?: any;
    celebrities?: Array<CelebsAttribute>;
}

export interface CelebsAttribute {
    id: number;
    name: string;
    friend_count?: number;
    status?: string;
    isAdd?: boolean;
    isMoved?: boolean;
    bindLabel?: string;
    UserProfile?: UserProfilesAttribute;
    celebrityTags?: any;
    classification: string;
    avatar_url: string;
    gender: string;
    birth_in_millis?: string | number;
}

export interface CelebrityTagAttribute {
    celeb_id?: number;
    name?: string;
    show?: boolean;
}

export interface ForceDepositsAttribute {
    id?: number;
    user_id?: number;
    summary?: string;
    deposit_type?: string;
    purchase_id?: number;
    amount?: number;
    balance?: number;
    status?: string;
    device_type?: string;
    device_name?: string;
    expires_at?: number;
    created_at?: number;
    created_at_tz?: string;
    updated_at?: number;
    ForceWithdrawalDetails?: Array<ForceWithdrawalDetailsAttribute>;
    ForceWithdrawals?: Array<ForceWithdrawalsAttribute>;
    Purchase?: PurchasesAttribute;
}

export interface ForceWithdrawalDetailsAttribute {
    id?: number;
    deposit_id?: number;
    withdrawal_id?: number;
    amount?: number;
    ForceDeposit?: ForceDepositsAttribute;
    ForceWithdrawal?: ForceWithdrawalsAttribute;
    deposit_status?: string;
}

export interface ForceWithdrawalsAttribute {
    id?: number;
    user_id?: number;
    summary?: string;
    amount?: number;
    status?: string;
    created_at?: number;
    updated_at?: number;
    device_type?: string;
    device_name?: string;
    tag?: string;
    isAbleCancel?: boolean;
    ForceWithdrawalDetails?: Array<ForceWithdrawalDetailsAttribute>;
    Fatebook?: any;
    type?: string;
    disableCancelAmount?: number;
}

export interface NoticesAttribute {
    id?: number;
    title?: string;
    body?: string;
    start_date?: any;
    status?: string;
    end_date?: any;
    extra?: string;
    banner_on_startup?: number | boolean | undefined;
    min_ver?: string;
    max_ver?: string;
    popup_img?: string;
    ios?: number | boolean | undefined;
    android?: number | boolean | undefined;
    onestore?: number | boolean | undefined;
    web?: number | boolean | undefined;
    created_at?: any;
    updated_at?: any | null;
    Reservations?: any[];
}

export interface PurchasesAttribute {
    disableCancelAmount?: number;
    id?: number;
    user_id?: number;
    purchasable_id?: number;
    coupon_id?: number;
    pay_amount?: number;
    transaction_id?: number;
    status?: string;
    meta?: string;
    created_at?: number;
    updated_at?: number;
    ForceDeposits?: Array<ForceDepositsAttribute>;
    Coupon?: CouponsAttribute;
    Purchasable?: PurchasablesAttribute;
    tag?: string;
    type?: string;
    isAbleRefund?: boolean;
    deposit_type?: string;
    deposit_amount?: any;
    isFirstBonus?: boolean;
    bonus_expires_at?: any;
}

export interface PurchasablesAttribute {
    id?: number;
    name?: string;
    store?: string;
    store_code?: string;
    prod_type?: string;
    price?: number;
    coin?: number;
    bonus_coin?: number;
    priority?: number;
    active?: boolean;
    meta?: any;
    currency?: string;
    isAdd?: any;
    isMoved?: any;
    Reservations?: ReservationsAttribute[];
    CouponHistories?: CouponHistoriesAttribute[];
}

export interface PremiumPurchasable {
    id: number;
    name: string;
    store: 'pay_item';
    store_code: string;
    prod_type: 'item';
    currency: 'KRW';
    price: number;
    coin?: 0;
    bonus_coin?: 0;
    priority?: number;
    active?: boolean;
    meta: { itemId: number };
}

export interface CouponsAttribute {
    id?: number;
    history_id?: number;
    serial_number?: string;
    status?: string;
    purchasable_id?: number;
    company_id?: number;
    meta?: number;
}

export interface CouponHistoriesAttribute {
    id?: number;
    purchasable_id?: number;
    company_id?: number;
    status?: string;
    coupon_file?: string;
    coupon_count?: number;
    created_at?: number;
    updated_at?: number;
    brief?: string;
    Coupons?: Array<CouponsAttribute>;
}

export interface ScenesAttribute {
    id?: number;
    sequence_id?: number;
    key?: string;
    type?: string;
    subtype?: any;
    lines?: any;
    script?: any | null;
    priority?: number;
    rev?: number;
    saved?: boolean;
    selected?: boolean;
}

export interface SequencesAttribute {
    id?: number;
    name?: string;
    extra?: any;
    Scenes?: ScenesAttribute[];
    PremiumItems?: PremiumItemsAttribute[];
}

export interface PremiumItemPromotionsAttribute {
    id?: number;
    premium_item_id?: number;
    title?: string;
    starts_at?: any;
    ends_at?: any;
    extra?: any;
    active?: number | boolean;
    created_at?: Date;
    updated_at?: Date;
    tag?: string;
    salePrice?: number;
}

export interface PremiumItemRecommendationsAttribute {
    id?: number;
    premium_item_id?: number;
    link?: string;
    title?: string;
    description?: string;
    priority?: number;
    tag?: string;
}

export interface PremiumItemParamCommentsAttribute {
    premium_item_id?: number;
    name?: string;
    comment?: number;
}

export interface PremiumItemSamplesAttribute {
    id?: number;
    premium_item_id?: number;
    image?: string;
    priority?: number;
    fileName?: string;
}

export interface PremiumItemChapterParamsAttribute {
    premium_item_chapter_id?: number;
    name?: string;
    value?: any;
}

export interface PremiumItemChaptersAttribute {
    id?: number;
    premium_item_id?: number;
    chapter_id?: number;
    title?: string;
    toc?: any;
    extra?: any;
    priority?: number;
    PremiumItemChapterParams?: PremiumItemChapterParamsAttribute[];
}

export interface PremiumItemsAttribute {
    id?: number;
    title?: string;
    hero_image?: string;
    price?: number;
    isB2BType?: boolean;
    description?: string;
    type?: string;
    status?: string;
    purchasable_status?: string;
    created_at?: number;
    updated_at?: number;
    released_at?: number | string;
    guardian_id?: number;
    image_pack_id?: number;
    skin?: string;
    min_ver?: string;
    extra?: string;
    sequence_id?: number;
    ListItem?: any;
    PremiumItemSamples?: Array<PremiumItemSamplesAttribute>;
    PremiumItemPromotions?: Array<PremiumItemPromotionsAttribute>;
    PremiumItemParams?: Array<any>;
    PremiumItemCategories?: Array<PremiumItemCategoriesAttribute>;
    PremiumItemCategoryItems?: PremiumItemCategoryItemsAttribute;
    isAppliedPromotion?: boolean;
    salePrice?: number;
    isAdd?: boolean;
    externalUrl?: string;
    link?: Link;
    cat_id?: number;
    company_id?: number;
    list?: boolean;
    discount_available?: boolean;
    premiumItemTags?: { name: string }[];
    ThemeViewGenres?: any[];
    Genres?: any[];
}

export interface PremiumItemCategoryItemsAttribute {
    category_id?: number;
    premium_item_id?: number;
    child_category_id?: number;
    priority?: number;
}

export interface AppVersionsAttribute {
    id?: number;
    os?: string;
    ver?: string;
    description?: string;
    active?: boolean;
    created_at?: any;
    updated_at?: any;
}

export interface HelpCategoriesAttribute {
    code?: string;
    name?: string;
    send_auto_mail?: boolean;
    mail_body?: string;
    mail_subject?: string;
    active?: boolean;
}

export interface HelpLogsAttribute {
    id?: number;
    user_id?: number;
    device_id?: number;
    email?: string;
    detail?: string;
    extra?: string;
    title?: string;
    created_at?: any;
    updated_at?: any;
    HelpLogHistories?: Array<HelpLogHistoriesAttribute>;
}

export interface HelpLogHistoriesAttribute {
    id?: number;
    user_id?: number;
    log_id?: number;
    active?: boolean | null;
    status?: string;
    memo?: string;
    created_at?: any;
    updated_at?: any;
}

export interface PremiumItemCategoriesAttribute {
    id?: number;
    title?: string;
    subTitle?: string;
    status?: string;
    priority?: number;
    type?: string;
    extra?: any;
    PremiumItems?: Array<PremiumItemsAttribute>;
    PremiumItemCategoryItems?: Array<PremiumItemCategoryItemsAttribute>;
    PremiumItemCategoryChildren?: Array<PremiumItemCategoryChildrenAttribute>;
    isAdd?: boolean; // custom
    isStatus?: boolean; // custom
}

export interface PremiumItemCategoryChildrenAttribute {
    id?: number;
    title?: string;
    status?: string;
    priority?: number;
    type?: string;
    extra?: any;
    isAdd?: boolean; // custom
}

export interface ThemeAttribute {
    id?: number;
    uuid?: string;
    title?: string;
    subtitle?: string;
    type?: string;
    view_all_type?: string;
    status?: string;
    priority?: number;
    code?: string;
    hero_image?: string;
    android?: boolean;
    ios?: boolean;
    onestore?: boolean;
    web?: boolean;
    items?: any[];
    meta?: any;
    target?: any;
    targeting?: any;
}

export interface searchThemeItems {
    id: number;
    rel_id: number;
    title: string;
    type: string;
    isAdd?: boolean;
    label?: string;
}

export interface moreIcon {
    id?: number;
    name?: string;
    link?: any;
    icon?: string;
    code?: string;
    min_ver?: string;
    priority?: number;
    status?: string;
    created_at?: Date;
    updated_at?: Date;
}

export interface ThemeView {
    id?: number;
    type: string;
    rel_id: number;
    cat_code?: string;
    status: string;
    meta: ThemeViewMeta;
    themeViewCustom?: any;
    updated_at?: Date;
    ThemeViewGenres?: any[];
    themeViewTags?: any[];
    isChecked?: boolean;
}

export interface ThemeViewMeta {
    title?: string;
    subtitle?: string;
    description?: string;
    thumbnail_image?: string;
    full_image?: string;
    label_color?: string;
    label_text?: string;
    tags?: string[];
    code?: string;
    link?: Link;
}

export interface Link {
    type?: string;
    value?: string;
    params?: {
        queryParams?: {
            keyword?: string;
            groupId?: number;
        };
    };
}

export interface StatisticsPremiumFortune {
    premium_item_id: number;
    title: string;
    bonus: number;
    force: number;
    count: number;
    unique_count: number;
    total?: number;
    teller?: number;
}

export interface StatisticsPacakgeFortune {
    title: string;
    bonus: number;
    force: number;
    count: number;
    unique_count: number;
    total?: number;
}

export interface StatisticsSmallPayment {
    consume_id: number;
    consume_name: string;
    bonus: number;
    force: number;
    count: number;
    unique_count: number;
    total?: number;
}

export interface Gift {
    id: number;
    title: string;
    description?: string;
    caution?: string;
    type: string;
    meta?: GiftMeta;
    isChecked?: boolean;
}

export interface GiftMeta {
    force?: number;
    validAfter?: number;
    pitem?: number;
    citem?: number;
    discount?: number;
    url?: string;
    color?: string;
    icon?: string;
    thumbnailImage?: string;
}

export interface LeafletGiftCategory {
    id?: number;
    title?: string;
    leaflet_id?: number;
    starts_at?: string | Date;
    ends_at?: string | Date;
    leafletGiftCategoryItems: [];
    priority: number;
    isAdd: boolean;
}

export interface HeroBackgroundsAttribute {
    id?: number;
    title?: string;
    day_image?: string;
    night_image?: string;
    day_result_image?: string;
    night_result_image?: string;
    status?: 'S0' | 'S1' | 'S2' | 'S3' | boolean;
    starts_at?: any;
    ends_at?: any;
    priority?: number | boolean;
}

export interface PostsAttribute {
    id?: number;
    type: string;
    subtype: string;
    title?: string;
    subtitle?: string;
    meta?: any;
    items?: any;
    status?: 'S0' | 'S1' | 'S2' | 'S3' | boolean;
    target?: any;
    targeting?: any;
    release_type: string;
    release_start?: any;
    release_end?: any;
    thumbnailList?: any;
    Reservations?: any;
    giftData?: any;
    fixed_position: number;
    presetTitle?: string;
}

export interface PostPresetAttribute {
    id?: number;
    title: string;
    target: any;
    status: any;
}

export interface PostSceneAttribute {
    id?: number;
    title?: string;
    items?: any;
    status?: string;
}

export interface SceneAttribute {
    title: string;
    question: any[];
    answer: any[];
}

export interface MetaAttribute {
    portrait: string;
    headerBgImage: string;
    headerBgColor: string;
    bodyBgImage: string;
    bodyBgColor: string;
    isDark: boolean;
    leafletId: any;
    presetId: number;
    postSceneId: number;
    fortuneId?: number;
    fortuneIds?: any[];
    itemType: string;
    itemSize: number;
}

export interface TargetAttribute {
    version?: NumberRange | StringRange;
    android?: boolean;
    ios?: boolean;
    onestore?: boolean;
    web?: boolean;
    userIds?: number[] | [];
    signup?: StringRange;
    daysAfterSignup?: number;
    daysPassAfterSignup?: number;
    dayOfWeeks?: any;
    genders?: string[];
    birth?: StringRange;
    hmUnsure?: boolean;
    animals?: any;
    originalAnimals?: any;
    zodiacs?: any;
    total?: NumberRange;
    force?: NumberRange;
    bonus?: NumberRange;
    lastPurchaseDate?: StringRange;
    purchaseDate?: StringRange;
    notPurchaseDate?: StringRange;
    fatebookCount?: NumberRange;
    lastLoginDate?: StringRange;
    fortuneItemIds?: number[] | [];
    fortuneItemNoIds?: number[] | [];
    fortuneItemTags?: number[] | [];
    fortuneItemCategories?: number[] | [];
    fortuneItemRecord?: {
        day: number;
        ids: number[] | [];
    };
    premiumItemIds?: [] | [];
    premiumItemNoIds?: [] | [];
    notSeenPremiumItemIds?: [] | [];
    premiumItemRecord?: {
        day: number;
        ids: number[] | [];
    };
    friendCount?: NumberRange;
    friendAddCount?: NumberRange;
    friendInviteCount?: NumberRange;
    friendCelebCount?: NumberRange;
    canSlotMachine?: boolean;
    chulcheck?: boolean;
    push?: boolean;
    anonymous?: boolean;
    chargeRecord?: NumberRange;
    withdrawalRecord?: NumberRange;
    birthday?: boolean;
    dailyScore?: NumberRange;
    giftIds?: number[] | [];
    notUsedGiftIds?: number[] | [];
    existGiftIds?: number[] | [];
    purchaseCount?: NumberRange;
    customVariable?: {};
    isWelcomeUser?: boolean;
    isLuckyLuckUser?: boolean;
    isFirstPackageUser?: boolean;
    isCheckTime?: boolean;
    levels?: any;
    jpLevels?: any;
    timezone?: NumberRange;
    hasCharged?: boolean;
    daysAfterLastCharge?: NumberRange;
    hasPremiumItem?: boolean;
    hasFortuneItem?: boolean;
    hasVoucher?: boolean;
    hasPremiumItemIn30Day?: boolean;
    daysAfterLastPremium?: number;
    daysWithinLastPremium?: number;
    isFirstSignUp?: boolean;
}

export interface StringRange {
    start?: string;
    end?: string;
}

export interface NumberRange {
    start?: number;
    end?: number;
}

export type premiumGroupTab =
    | 'all'
    | 'general'
    | 'rank'
    | 'carousel'
    | 'button'
    | 'banner'
    | 'tag'
    | 'category';
export type status = 'S0' | 'S1' | 'S2' | 'S3';
export type groupItemType = 'search' | 'category' | 'guardian' | 'customUrl';
export type groupItem = {
    id?: number;
    title?: string;
    status?: string;
    type?: string;
    bindLabel?: string;
    groupType?: string;
    PremiumItemPromotions?: PremiumItemPromotionsAttribute[];
};
export type keyword = { link: Link; text: string };

export interface premiumGroup {
    id: number;
    title: string;
    type: premiumGroupTab;
    status: status;
    priority?: number;
    list: boolean;
    items?: groupItem[];
    childPremiumGroups?: groupItem[];
    extra?: {
        subTitle?: string;
        topImage?: string;
        topBgColor?: string;
        topImageHeight?: number;
        thumbnailImage?: string;
        thumbnailWidth?: number;
        thumbnailHeight?: number;
        bannerImage?: string;
        bannerBgColor?: string;
        keywords?: keyword[];
        direct?: boolean;
        link?: Link;
        categoryId?: number;
        guardianId?: number;
        listType?: 'thumbnail' | 'text';
        isFixed?: false;
        categoryMapping?: {
            genreIds: number[];
            subjectIds: number[];
        };
    };
}

export interface MetaTemplatesAttribute {
    id?: number;
    type?: string;
    name?: string;
    title?: string;
    active?: any;
    template?: string;
    ios?: any;
    android?: any;
    onestore?: any;
    minVerNum?: any;
    maxVerNum?: any;
}

export interface FreeChargeItemsAttribute {
    id?: string;
    title: string;
    subtitle: string;
    image_url: string;
    link: Link & { title: string };
    status: 'S0' | 'S1' | 'S2' | 'S3';
    priority?: number;
    targeting?: Record<string, unknown>;
    android: boolean;
    ios: boolean;
    onestore: boolean;
    min_ver: string;
    max_ver: string;
}
export interface AdItemsAttribute {
    id?: number | string;
    area_id?: string;
    image_url?: string;
    active?: any;
    is_admin?: any;
    target?: any;
    priority?: number;
    bg_color?: any;
    isChangeActive?: any;
    selectable?: boolean;
    fileName?: string;
    android?: boolean;
    ios?: boolean;
    onestore?: boolean;
    web?: boolean;
    min_ver_num?: any;
    max_ver_num?: any;
    reservation?: any;
    Reservations?: Array<ReservationsAttribute>;
    targeting?: any;
}

export interface AdBottomsAttribute {
    id?: number;
    name?: string;
    description?: string;
    status?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface AdBottomItemsAttribute {
    id?: number;
    adBottomId?: number | string;
    type?: string;
    info?: {
        text?: string;
        link?: string;
        image?: string;
        imageLink?: string;
        presetId?: number;
    };
    title: string;
    status?: string;
    startedAt?: Date;
    endedAt?: Date;
    adBottomItemTargets?: AdBottomItemTargetsAttribute[];
    targeting?: any;
}

export interface AdBottomItemTargetsAttribute {
    id?: number;
    adBottomItemId?: number;
    type: 'DEFAULT' | 'CATEGORY' | 'ITEM' | 'KEY';
    key?: string;
    itemId?: number;
    categoryId?: number;
    keys?: string[];
}

export interface GenresAttribute {
    id?: number;
    name: string;
    icon: string;
    tag?: string;
    status?: 'S3' | 'S2' | 'S1' | 'S0';
    priority?: number;
    extra?: { [key: string]: string | number } | string;
    createdAt?: Date;
    updatedAt?: Date;

    premiums?: PremiumItemsAttribute[];
}

export interface AppSectionList {
    title: string;
    list: AppSection[];
}

export interface AppSection {
    id: number;
    title?: string;
    page_type?: string;
    template_type?: string;
    status: 'S3' | 'S2' | 'S1' | 'S0';
    priority?: number;
    meta?: any;
    target_id?: number;
    targeting?: Record<string, any>;
    ios?: boolean;
    android?: boolean;
    Target?: AppSectionTarget;
    ThemeViews?: ThemeView[];
}

export interface AppSectionTarget {
    id: number;
    status: 'S3' | 'S2' | 'S1' | 'S0';
    title?: string;
    target?: Record<string, any>;
}

export interface ConsumableItem {
    id?: number;
    title?: string;
    premium_item_id?: number;
    price?: number;
    code?: string;
    status?: string;
    sub_title?: string,
    alert_title?: string,
    alert_msg?: string,
    code_group?: string,
    priority?: number,
}
