import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '../base.api.service';

@Injectable()
export class FreeChargeItemsService extends BaseAPIService {
    private ROUTES = {
        list: '/free-charge/item',
        save: '/free-charge/item',
        get: '/free-charge/item/:id',
        update: '/free-charge/item/:id',
        delete: '/free-charge/item/:id',
    };

    constructor(protected http: HttpClient) {
        super(http);
    }

    list(): Promise<any> {
        return this.GET(this.ROUTES.list).toPromise();
    }

    get(id: any): Promise<any> {
        console.log(this.GET(this.ROUTES.get, { id }).toPromise());
        return this.GET(this.ROUTES.get, { id }).toPromise();
    }

    save(item): Promise<any> {
        return this.POST(this.ROUTES.save, null, { item }).toPromise();
    }

    update(id: any, item: any): Promise<any> {
        console.log('server: ', item);
        return this.PATCH(this.ROUTES.update, { id }, { item }).toPromise();
    }

    delete(id: any): Promise<any> {
        return this.DELETE(this.ROUTES.delete, { id }).toPromise();
    }
}
