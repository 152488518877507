import {
    Component,
    OnInit,
    ViewEncapsulation,
    AfterViewInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'ng2-ui-auth';
import { environment } from 'environments/environment';

declare let mLayout: any;

@Component({
    selector: 'app-aside-nav',
    templateUrl: './aside-nav.component.html',
    styleUrls: ['./aside-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {
    public language = environment.language;
    public link = {
        free_fortune: environment.link.free_fortune,
        friend: environment.link.friend,
        search: environment.link.search,
    };
    public style = {
        icon_color: '#525672',
        text_color: '#fff',
        header_nav_color: '#000',
        aside_nav_color: 'linear-gradient( to bottom, #000, #a18cd1 )',
    };
    public styles = {
        ko: {
            text_color: 'rgba(255, 255, 255, 0.7)',
            header_nav_color: '#2c2e3e',
            aside_nav_color: '#2c2e3e',
        },
        jp: {
            text_color: 'rgba(0,0,0,.8)',
            header_nav_color: '#fbc2eb',
            aside_nav_color: 'linear-gradient( to bottom, #fbc2eb, #a18cd1 )',
        },
        en: {
            text_color: 'rgba(0,0,0,.8)',
            header_nav_color: '#b2ffcf',
            aside_nav_color:
                'radial-gradient( circle farthest-corner at 10% 10%, rgba(161,255,206,1) 0%, rgba(250,255,209,1) 90% )',
        },
        dev: {
            text_color: '#fff',
            icon_color: 'rgba(255, 255, 255, 0.7)',
            header_nav_color: '#000',
            aside_nav_color: 'linear-gradient( to bottom, #000, #a18cd1 )',
        },
    };

    public menus: any[] = [
        {
            name: '무료 운세',
            subMenus: [
                { name: '아이템', link: '/fortune-item/list' },
                { name: '결과 이미지 템플릿', link: '/fortune-item/result-template' },
                { name: '카테고리', link: '/fortune-item/category' },
                { name: '추가 항목', link: '/fortune-item/extra/list' },
                { name: '하단 광고', link: '/fortune-item/ad' },
                { name: '아이템별 하단 광고', link: '/fortune-item/ad/mapping' },
            ],
            icon: 'la la-github-alt',
        },
        {
            name: '프리미엄 운세',
            subMenus: [
                { name: '아이템', link: '/premium-item/list' },
                { name: '장르/키워드', link: '/premium-item/subjects' },
                { name: '그룹', link: '/premium-item/group' },
                { name: '시퀀스', link: '/premium-item/sequence/list' },
                { name: '챕터 템플릿', link: '/premium-item/chapter/list' },
                { name: '프리미엄 프로모션', link: '/premium-item/promotion/list' },
                {
                    name: '소액 결제 프로모션',
                    link: '/premium-item/promotion/small/list',
                },
            ],
            icon: 'la la-money',
        },
        {
            name: '연예인 궁합',
            subMenus: [
                { name: '아이템', link: '/celeb/item/list' },
                { name: '테마', link: '/celeb/theme/list' },
            ],
            icon: 'flaticon-black',
        },
        {
            name: '테마',
            subMenus: [
                { name: '목록', link: '/tab/theme/list' },
                { name: '아이템', link: '/tab/theme-view/list' },
                { name: '커스텀 아이템', link: '/tab/theme-view/custom/list' },
                { name: '싱크', link: '/tab/theme/sync' },
            ],
            icon: 'flaticon-star',
        },
        {
            name: '투데이',
            subMenus: [
                { name: '포스트', link: '/today/post/list' },
                { name: '슬롯', link: '/today/post/slot' },
                { name: '씬즈', link: '/today/post/scene' },
                { name: '히어로 섹션', link: '/today/hero' },
            ],
            icon: 'la la-hand-peace-o',
        },
        {
            name: '유저',
            subMenus: [{ name: '타겟 세그먼트', link: '/today/post/preset' }],
            icon: 'la la-user',
        },
        {
            name: '라이브',
            subMenus: [
                { name: '이미지팩', link: '/live/image-pack/list' },
                { name: '배너', link: '/live/banner/area-list' },
                { name: '이벤트', link: '/live/leaflet/list' },
                { name: '공지사항', link: '/live/notice/list' },
                { name: '무료 충전소', link: '/live/free-charge/list' },
                { name: '(구) 무료 충전소', link: '/live/freeforce/list' },
                { name: '앱/웹 오픈 그래프', link: '/live/open-graph/list' },
                {
                    name: '마중물 오픈 그래프',
                    link: '/live/open-graph-interactive/list',
                },
                { name: '푸시 & 알림함', link: '/live/push/list' },
                { name: '더 보기 메뉴', link: '/live/more-icon/list' },
                { name: '인기 검색어', link: '/live/popular-search' },
            ],
            icon: 'flaticon-imac',
        },
        {
            name: 'CS',
            subMenus: [
                { name: '회원 조회', link: '/cs/user-search' },
                { name: '1:1 문의', link: '/cs/history' },
                { name: '자동 메일', link: '/cs/auto-response' },
                { name: 'FAQ', link: '/cs/faq' },
                { name: '약관', link: '/cs/terms' },
                { name: '커뮤니티', link: '/cs/community' },
            ],
            icon: 'flaticon-users',
        },
        {
            name: '포스',
            subMenus: [
                { name: '포스팩', link: '/force/pack/list' },
                { name: '쿠폰', link: '/force/coupon/list' },
                { name: '선물함', link: '/force/gift/list' },
                { name: '제휴사', link: '/force/company/list' },
            ],
            icon: 'flaticon-coins',
        },
        {
            name: '통계',
            subMenus: [
                { name: '가입자', link: '/statistics/user' },
                { name: '무료 운세', href: this.link.free_fortune },
                { name: '연예인 궁합', link: '/statistics/celeb' },
                { name: '프리미엄 운세', link: '/statistics/premium-fortune' },
                { name: '패키지 운세', link: '/statistics/package-fortune' },
                { name: '이벤트 & 트래킹', link: '/statistics/event' },
                { name: '친구', href: this.link.friend },
                { name: '검색어', href: this.link.search },
                {
                    name: '매출',
                    subMenus: [
                        { name: '일별 통계', link: '/statistics/sales/daily' },
                        { name: '잔액 통계', link: '/statistics/sales/balance' },
                        {
                            name: '신규 구매자 통계',
                            link: '/statistics/sales/new-purchase',
                        },
                    ],
                },
                { name: 'Withdrawal', link: '/statistics/sync' },
            ],
            icon: 'flaticon-diagram',
        },
        {
            name: '기타',
            subMenus: [
                { name: 'FAS', link: '/etc/fas/list' },
                { name: '앱 버전', link: '/etc/version' },
                { name: '무료아이템 테마', link: '/fortune-item/theme' },
                { name: '임시 기능', link: '/etc/temp-feature' },
            ],
            icon: 'la la-ellipsis-h',
        },
        {
            name: 'AI',
            subMenus: [
                { name: '아이템', link: '/ai/item' },
                { name: '프롬프트', link: '/ai/prompt' },
                { name: '텔러', link: '/ai/authors' },
                { name: '공지사항', link: '/ai/notice' },
            ],
            icon: 'la la-android',
        },
        { name: '내 계정 관리', link: '/my/account' },
        {
            name: '외부 관리',
            link: '/external/list',
            icon: 'la la-diamond',
        },
        {
            name: '도구',
            subMenus: [
                { name: '딥링크', link: '/tools/deeplink' },
                { name: '기본', link: '/tools/location-finder' },
                { name: '프리미엄커버', link: '/tools/cover' },
            ],
            icon: 'flaticon-cogwheel',
        },
    ];
    public langs: any[];
    public thisLang: any;
    public production = environment.production;

    constructor(private router: Router, private auth: AuthService) { }

    ngOnInit() {
        const type =
            environment.language === 'ko'
                ? environment.production
                    ? 'ko'
                    : 'dev'
                : environment.language;
        this.style = { ...this.style, ...this.styles[type] };

        if (environment.language === 'en') {
            this.menus = [
                {
                    name: '무료 운세',
                    subMenus: [
                        { name: '아이템', link: '/fortune-item/list' },
                        { name: '결과 이미지 템플릿', link: '/fortune-item/result-template' },
                        { name: '카테고리', link: '/fortune-item/category' },
                        { name: '추가 항목', link: '/fortune-item/extra/list' },
                        { name: '하단 광고', link: '/fortune-item/ad' },
                        { name: '아이템별 하단 광고', link: '/fortune-item/ad/mapping' },
                    ],
                    icon: 'la la-github-alt',
                },
                {
                    name: '프리미엄 운세',
                    subMenus: [
                        { name: '아이템', link: '/premium-item/list' },
                        { name: '장르/키워드', link: '/premium-item/subjects' },
                        { name: '그룹', link: '/premium-item/group' },
                        { name: '시퀀스', link: '/premium-item/sequence/list' },
                        { name: '챕터 템플릿', link: '/premium-item/chapter/list' },
                        { name: '프리미엄 프로모션', link: '/premium-item/promotion/list' },
                        {
                            name: '소액 결제 프로모션',
                            link: '/premium-item/promotion/small/list',
                        },
                    ],
                    icon: 'la la-money',
                },
                {
                    name: '투데이',
                    subMenus: [{ name: '포스트', link: '/today/post/list' }],
                    icon: 'la la-hand-peace-o',
                },
                {
                    name: '테마',
                    subMenus: [
                        { name: '목록', link: '/tab/theme/list' },
                        { name: '아이템', link: '/tab/theme-view/list' },
                        { name: '커스텀 아이템', link: '/tab/theme-view/custom/list' },
                        { name: '싱크', link: '/tab/theme/sync' },
                    ],
                    icon: 'flaticon-star',
                },
                {
                    name: '섹션',
                    subMenus: [{ name: '목록', link: '/section/list' }],
                    icon: 'la la-server',
                },
                {
                    name: '유저',
                    subMenus: [{ name: '타겟 세그먼트', link: '/today/post/preset' }],
                    icon: 'la la-user',
                },
                {
                    name: '라이브',
                    subMenus: [
                        { name: '이미지팩', link: '/live/image-pack/list' },
                        { name: '배너', link: '/live/banner/area-list' },
                        { name: '이벤트', link: '/live/leaflet/list' },
                        { name: '공지사항', link: '/live/notice/list' },
                        { name: '푸시 & 알림함', link: '/live/push/list' },
                        { name: '더 보기 메뉴', link: '/live/more-icon/list' },
                        { name: '인기 검색어', link: '/live/popular-search' },
                    ],
                    icon: 'flaticon-imac',
                },
                {
                    name: 'CS',
                    subMenus: [
                        { name: '회원 조회', link: '/cs/user-search' },
                        { name: '1:1 문의', link: '/cs/history' },
                        { name: '자동 메일', link: '/cs/auto-response' },
                        { name: 'FAQ', link: '/cs/faq' },
                        { name: '약관', link: '/cs/terms' },
                    ],
                    icon: 'flaticon-users',
                },
                {
                    name: '포스',
                    subMenus: [
                        { name: '포스팩', link: '/force/pack/list' },
                        { name: '쿠폰', link: '/force/coupon/list' },
                        { name: '선물함', link: '/force/gift/list' },
                        { name: '제휴사', link: '/force/company/list' },
                    ],
                    icon: 'flaticon-coins',
                },
                {
                    name: '통계',
                    subMenus: [
                        { name: '가입자', link: '/statistics/user' },
                        { name: '무료 운세', href: this.link.free_fortune },
                        { name: '프리미엄 운세', link: '/statistics/premium-fortune' },
                        { name: '패키지 운세', link: '/statistics/package-fortune' },
                        { name: '이벤트 & 트래킹', link: '/statistics/event' },
                        { name: '친구', href: this.link.friend },
                        { name: '검색어', href: this.link.search },
                        {
                            name: '매출',
                            subMenus: [
                                { name: '일별 통계', link: '/statistics/sales/daily' },
                                { name: '잔액 통계', link: '/statistics/sales/balance' },
                                {
                                    name: '신규 구매자 통계',
                                    link: '/statistics/sales/new-purchase',
                                },
                            ],
                        },
                        { name: 'Withdrawal', link: '/statistics/sync' },
                    ],
                    icon: 'flaticon-diagram',
                },
                {
                    name: '기타',
                    subMenus: [
                        { name: 'FAS', link: '/etc/fas/list' },
                        { name: '앱 버전', link: '/etc/version' },
                    ],
                    icon: 'la la-ellipsis-h',
                },
                {
                    name: 'AI',
                    subMenus: [
                        { name: '아이템', link: '/ai/item' },
                        { name: '프롬프트', link: '/ai/prompt' },
                        { name: '텔러', link: '/ai/authors' },
                        { name: '공지사항', link: '/ai/notice' },
                    ],
                    icon: 'la la-android',
                },
                { name: '내 계정 관리', link: '/my/account' },
                {
                    name: '외부 관리',
                    link: '/external/list',
                    icon: 'la la-diamond',
                },
            ];
        }

        this.langs = [
            {
                name: 'Korean',
                code: 'KO',
                flag: '🇰🇷',
                url: 'https://analytics.forceteller.com',
            },
            {
                name: 'Japanese',
                code: 'JP',
                flag: '🇯🇵',
                url: 'https://analytics.forceteller.jp',
            },
            {
                name: 'English',
                code: 'EN',
                flag: '🇺🇸',
                url: 'https://analytics-en.forceteller.com',
            },
        ];
        this.thisLang = this.langs.filter(
            (l) => l.code == this.language.toUpperCase(),
        )[0];
    }

    ngAfterViewInit() {
        mLayout.initAside();
    }

    logout() {
        this.auth.logout().subscribe(
            () => {
                alert('성공적으로 로그아웃 되었습니다.');
                this.router.navigateByUrl(this.router.createUrlTree(['login']));
            },
            (e) => {
                console.log(e);
                alert('로그아웃 실패, 잠시 후 다시 시도하십시오.');
            },
        );
    }
}
